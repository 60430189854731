import { SYNTHETIC_USER_IDS } from "../../app/constants";
import { ORG_CLASSIFICATION, PENDO_EVENTS, PENDO_PLATFORM, PENDO_ROLES } from "../../config";

export function checkReviewerPermissions(permissions) {
  for (var i = 0; i < permissions.length; i++) {
    var perm = permissions[i];
    var reviewModel = perm.split(":");
    if (reviewModel.length === 2 && reviewModel[1] === "review") {
      return true;
    }
  }
  return false;
}

export function checkClassTestPermissions(permissions) {
  for (var i = 0; i < permissions.length; i++) {
    var perm = permissions[i];
    var betaModel = perm.split(":");
    if (betaModel.length === 2 && betaModel[1] === "beta") {
      return true;
    }
  }
  return false;
}

export function getOrgClassification(currentInstitute) {
  if (
    (currentInstitute.organization &&
      currentInstitute.organization.classification &&
      (currentInstitute.organization.classification.toLowerCase() ===
        "higher ed" ||
        currentInstitute.organization.classification.toLowerCase() ===
          "higher education")) ||
    (currentInstitute.classification &&
      (currentInstitute.classification.toLowerCase() === "higher ed" ||
        currentInstitute.classification.toLowerCase() === "higher education"))
  ) {
    return ORG_CLASSIFICATION.HIGHER_ED;
  } else {
    return ORG_CLASSIFICATION.SCHOOL;
  }
}

const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  
  return "";
};


export const getOneTrustCookieConsent = () => {
  const onetrustCookie = getCookie("OptanonConsent");
  if (onetrustCookie) {
    const optanonConsent = onetrustCookie.split("&");
    for (let i = 0; i < optanonConsent.length; i++) {
      if (optanonConsent[i].includes("groups=")) {
        return optanonConsent[i].replace("groups=", "");
      }
    }
  }
  else if (window.piSession?.piCookiePreferences()) {
    return window.piSession.piCookiePreferences();
  }
  else return "";
};

export const getOneTrustCookieConsentHosts = () => {
  const onetrustCookie = getCookie("OptanonConsent");
  if (onetrustCookie) {
    const optanonConsent = onetrustCookie.split("&");
    for (let i = 0; i < optanonConsent.length; i++) {
      if (optanonConsent[i].includes("hosts=")) {
        return optanonConsent[i].replace("hosts=", "");
      }
    }
  }
  else if (window.piSession?.piCookiePreferences()) {
    return window.piSession.piCookiePreferences();
  }  
  else return "";
  
};

export const getOneTrustCookieConsentGroups = () => {
  const onetrustCookie = getCookie("OptanonConsent");
  if (onetrustCookie) {
    const optanonConsent = onetrustCookie.split("&");
    for (let i = 0; i < optanonConsent.length; i++) {
      if (optanonConsent[i].includes("groups=")) {
        return optanonConsent[i].replace("groups=", "");
      }
    }
  }
  else if (window.piSession?.piCookiePreferences()) {
    return window.piSession.piCookiePreferences();
  }  
  else return "";
  
};

export const formatOneTrustConsent = (consent) => {
  const consentArray = consent.split(",");
  const consentJson = {};

  for (const item of consentArray) {
    const [key, value] = item.split(":");
    consentJson[key] = parseInt(value);
  }

  return consentJson;
};

export const isUserConsentToAnalytics = () => {
  const pearsonCookieGroups = getOneTrustCookieConsentGroups();
  const pearsonConsent = formatOneTrustConsent(pearsonCookieGroups);
  const analytics = "C0002"
  return pearsonConsent?.[analytics] === 1;
}

export const getUserIntegrationType = () => {
  const queryParams = new URLSearchParams(window.location.search);
  let userIntegrationType;
  if (queryParams.has("integration_context_token")) {
    userIntegrationType = "LMS";
  }
  if(queryParams.has("ref")) {
    userIntegrationType = queryParams.get("ref")
  }
  return userIntegrationType;
}

export const isProd = () => {
  const config = window.__env.configs["appConfig"];
  return config?.ENV === 'prod';
}

export const isUserSynthetic = (userData) => isProd() && SYNTHETIC_USER_IDS.includes(userData?.id);

export const getPendoRoleFromUserRole = (userRole) => {
  const role = userRole?.toLowerCase() ?? '';
  if(role.includes("instructor")) {
    return PENDO_ROLES.INSTRUCTOR;
  }
  else if (role.includes("student")) {
    return PENDO_ROLES.STUDENT
  }
  else {
    return undefined;
  }
}

/**
 * @param {Object} userInfo 
 * @param {String} userRole 
 * @param {Object} currentInstitute 
 * @param {Object} productData 
 * @param {String} platform
 * @returns { visitor, account, analytics }
 */
export const formatPendoObjectForUser = (userInfo, userRole, currentInstitute, productData, discipline = undefined) => {
  const excludeEvents = !isUserConsentToAnalytics() ? PENDO_EVENTS : [] //user consented don't send any events to exclude
  const analytics = {
    excludeEvents
  }

  const pendoObject = {
    visitor: {
      id: "",
      platform: PENDO_PLATFORM,
      bookId: productData?.id ?? undefined,
      integrationType: getUserIntegrationType() ?? undefined,
      userAnalyticsConsent: isUserConsentToAnalytics() ?? false,
      discipline,
    },
    account: undefined,
    analytics
  };

  //anonymouse user
  if (!userInfo) {
    return pendoObject;
  }
  
  //logged in user
  const { id, userDetails } = userInfo;
  const { organization } = currentInstitute ?? { id: "", organization: {}};
  
  const visitor = {
    ...pendoObject.visitor,
    id,
    userRole: getPendoRoleFromUserRole(userRole),
    userCountryCode: userDetails?.homeCountryCode ?? undefined,
  }

  const account = {
    id: organization?.orgId ?? undefined,
    orgCountryCode: organization?.location?.country ?? undefined,
    name: organization?.name ?? undefined,
  }

  return { visitor, account, analytics }
}
