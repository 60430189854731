import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { selectCurrentInstitute, selectUserData, selectUserRole } from "../components/widgets/exHeader/userSlice";
import { selectProductData } from "../components/pages/pdp/productSlice";
import { formatPendoObjectForUser } from "../components/util/userUtil";
import { selectSubject } from "../components/widgets/subject/subjectSlice";


export const usePendo = () => {
    const [isInit, setIsInit] = useState(false);
    const userData = useSelector(selectUserData);
    const currentInstitute = useSelector(selectCurrentInstitute);
    const productData = useSelector(selectProductData);
    const userRole = useSelector(selectUserRole);
    const subject = useSelector(selectSubject);

    useEffect(() => {
      if (window?.pendo) {
        const { initialize, updateOptions, clearSession } = window?.pendo;
        const action = !isInit ? initialize : updateOptions;
        if(!window?.piSession?.userId()) {
          clearSession();
          window?.localStorage.clear();
        }
        const { account, visitor, analytics } = formatPendoObjectForUser(
          userData,
          userRole,
          currentInstitute,
          productData,
          subject
        ) ?? {};
        action?.({ account, visitor, analytics });
        setIsInit(true)
      }
    }, [window?.pendo, window?.piSession, currentInstitute, productData, userRole, userData, subject])
}