import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./app.scss";
import "./onetrust.scss";
import { BrowserRouter as Router } from "react-router-dom";

axios.interceptors.request.use(
  (config) => {
    if (
      window.piSession &&
      window.piSession.hasValidSession(10) === "success"
    ) {
      const token = window.piSession.currentToken();

      if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["piid"] = window.piSession.userId();
      }
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

let config = window.__env.configs["appConfig"];

if (config.ENV === "stg" || config.ENV === "perf" || config.ENV === "prod") {
  const nrScript = document.createElement("script");
  nrScript.src = "/config/newrelic-browser-config.js";
  const firstScript = document.querySelector("head script:first-of-type");
  document.head.insertBefore(nrScript, firstScript);
}

//OneTrust banner not required to load in the QA envrioment due to prsn domain 
//OneTrust banner will properly worked under pearson.com domain
if (config.ENV !== "qa") {
  const otAutoBlockScript = document.createElement('script');
  otAutoBlockScript.setAttribute("src", "https://cdn.cookielaw.org/consent/0d545d30-0e52-4bd4-bbe4-db2d55525649/OtAutoBlock.js");
  document.head.insertBefore(otAutoBlockScript, document.querySelector('head script:first-of-type'));

  const otSdkStubScript = document.createElement('script');
  otSdkStubScript.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
  otSdkStubScript.setAttribute("type", "text/javascript");
  otSdkStubScript.setAttribute("charset", "UTF-8");
  otSdkStubScript.setAttribute("data-domain-script", "0d545d30-0e52-4bd4-bbe4-db2d55525649");
  document.head.insertBefore(otSdkStubScript, document.querySelector('head script:first-of-type'));
}

let piSessionScript = document.createElement("script");
piSessionScript.src = `${config.PI_API}/login/js/v2/session.js`;
document.body.appendChild(piSessionScript);

let inputPiUserId = document.createElement("input");
inputPiUserId.type = "hidden";
inputPiUserId.id = "pi_user_id";
document.body.appendChild(inputPiUserId);

function startPiSession() {
  let session = window.piSession;
  if (!session) return console.error("piSession did not load!");
  let config = window.__env.configs["appConfig"];
  window.piSession.initialize(config.PI_CLIENT_ID, {
    sessionIdleTimeoutSeconds: 1800,
  });

  if (window.performance) {
    if (
      window.performance.getEntriesByType("navigation") &&
      window.performance.getEntriesByType("navigation").length > 0 &&
      window.performance.getEntriesByType("navigation")[0].type !== "reload"
    ) {
      if (
        !(
          window.localStorage.getItem("exchange.user.ctanavigated") ===
          "true" &&
          (window.performance.getEntriesByType("navigation")[0].type ===
            "back_forward" ||
            window.performance.getEntriesByType("navigation")[0].type ===
            "navigate")
        )
      ) {
        window.localStorage.removeItem("exchange.user.defaultInstitute");
        window.localStorage.removeItem("exchange.user.ctanavigated");
      } else {
        window.localStorage.removeItem("exchange.user.ctanavigated");
      }
    }
    // window.performance.navigation.type is deprecated.
    // older veriosns from iOS 15.1 are not supporting 'performance.getEntriesByType("navigation")[0]'
    else if (
      window.performance.navigation &&
      window.performance.navigation.type !== 1
    ) {
      if (
        !(
          window.localStorage.getItem("exchange.user.ctanavigated") ===
          "true" &&
          (window.performance.navigation.type === 2 ||
            window.performance.navigation.type === 0)
        )
      ) {
        window.localStorage.removeItem("exchange.user.defaultInstitute");
        window.localStorage.removeItem("exchange.user.ctanavigated");
      } else {
        window.localStorage.removeItem("exchange.user.ctanavigated");
      }
    }
  }

  window.piSession.on(window.piSession.RefreshEvent, function (eventData) {
    ReactDOM.render(
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  });

  session.on(session.SessionStateKnownEvent, function () {
    if (window.piSession.hasValidSession(10) === "notoken") {
      ReactDOM.render(
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>,
        document.getElementById("root")
      );
    } else if (
      window.piSession.userId() === null &&
      window.piSession.hasValidSession(10) === "nosession"
    ) {
      ReactDOM.render(
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>,
        document.getElementById("root")
      );
    } else if (
      window.piSession.userId() !== null &&
      window.piSession.hasValidSession(10) === "nosession"
    ) {
    } else if (
      window.piSession.userId() !== null &&
      window.piSession.hasValidSession(10) === "success"
    ) {
      ReactDOM.render(
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>,
        document.getElementById("root")
      );
    } else {
      ReactDOM.render(
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>,
        document.getElementById("root")
      );
    }

    session.on(session.LoginEvent, function () {
      if (session.hasValidSession(10) === "success") {
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    });

    session.on(session.LogoutEvent, function () {
      window.sessionStorage.removeItem("exchange.product.baseRelated");
      window.sessionStorage.removeItem("exchange.search.selectedPlatform");
      window.sessionStorage.removeItem(
        "exchange.search.selectedPlatformDisplayName"
      );
      window.sessionStorage.removeItem("exchange.search.layout");
      window.sessionStorage.removeItem("exchange.search.selectedShow");
      window.sessionStorage.removeItem(
        "exchange.search.selectedShowDisplayName"
      );
      window.sessionStorage.removeItem("exchange.search.selectedSort");
      window.sessionStorage.removeItem(
        "exchange.search.selectedSortDisplayName"
      );
      window.localStorage.removeItem("exchange.user.defaultInstitute");
      window.localStorage.removeItem("exchange.user.lmsInstitute");
      window.localStorage.removeItem("exchange.user.ctanavigated");
      let params = new URLSearchParams(window.location.search);
      params.delete("disc");
      params.delete("cat");
      window.piSession.login(
        `${window.location.origin}${window.location.pathname
        }?${params.toString()}`
      );
    });
  });
}
piSessionScript.onload = startPiSession;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
